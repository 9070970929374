import React, { Fragment } from "react";

import { Advertisement } from "./advertisement";
import { ProviderForm } from "./provider-form";
import { LoginGrid } from "./styles";

import { useWindowSize } from "hooks";
import { LaptopModal } from "pages/dashboard/laptop-modal";

const BigScreenLogin: React.FC = (): JSX.Element => {
  const { width } = useWindowSize();
  const MOBILE_BREAKPOINT = 768;

  return (
    <Fragment>
      <LoginGrid>
        <ProviderForm />
        <Advertisement />
      </LoginGrid>
      <LaptopModal isOpen={Boolean(width <= MOBILE_BREAKPOINT)} />
    </Fragment>
  );
};

export { BigScreenLogin };
