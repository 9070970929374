const CI_COMMIT_REF_NAME = "trunk";
const CI_COMMIT_SHA = "4c93e0ac0ba91c9a2e76ee9a3e029c401a3819fc";
const CI_COMMIT_SHORT_SHA = "4c93e0ac";
const INTEGRATES_BUCKET_NAME = "integrates.front.production.fluidattacks.com";
const INTEGRATES_DEPLOYMENT_DATE = "2025-01-25T03:35:10Z";

export {
  CI_COMMIT_REF_NAME,
  CI_COMMIT_SHA,
  CI_COMMIT_SHORT_SHA,
  INTEGRATES_BUCKET_NAME,
  INTEGRATES_DEPLOYMENT_DATE,
};
