/* eslint-disable functional/immutable-data */

const pagesenseEvent = (event: string): void => {
  const { pagesense } = window as typeof window & { pagesense?: unknown[] };
  pagesense?.push(["trackEvent", event]);
};

const pagesenseFreeTrial = (): void => {
  const referralLink = sessionStorage.getItem("ReferralLink");
  const events = [
    {
      event: "CompleteFreeTrialFromLinkedin",
      url: "https://www.linkedin.com/",
    },
    {
      event: "CompleteFreeTrialFromWeb",
      url: "https://fluidattacks.com/",
    },
  ];
  events.forEach((trackedEvent): void => {
    if (referralLink?.startsWith(trackedEvent.url) ?? false) {
      pagesenseEvent(trackedEvent.event);
    } else {
      pagesenseEvent("CompleteFreeTrial");
    }
  });
};

const setReferrerURL = (url: string): void => {
  sessionStorage.setItem("ReferralLink", url);
};

export { pagesenseFreeTrial, setReferrerURL, pagesenseEvent };
