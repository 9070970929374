import type { FC } from "react";
import React from "react";
import type { ToastContainerProps } from "react-toastify";
import { Slide, ToastContainer } from "react-toastify";
import { styled } from "styled-components";

type TToastBoxProps = Pick<
  ToastContainerProps,
  "autoClose" | "closeButton" | "closeOnClick" | "hideProgressBar" | "position"
>;

const StyledToastBox = styled(ToastContainer)`
  > .Toastify__toast {
    border-radius: 4px;
    margin-bottom: 6px;
    padding: 0;
    position: relative;

    .comp-alert {
      padding: 20px 12px;
    }

    > .comp-button {
      position: absolute;
      right: 6px;
      top: 6px;
    }
  }
`;
const AUTO_CLOSE_DELAY_MS = 5000;
const ToastBox: FC<TToastBoxProps> = ({
  autoClose = AUTO_CLOSE_DELAY_MS,
  position = "top-right",
}: Readonly<TToastBoxProps>): JSX.Element => (
  <StyledToastBox
    autoClose={autoClose}
    closeOnClick={false}
    hideProgressBar={true}
    position={position}
    transition={Slide}
  />
);

export type { TToastBoxProps };
export { ToastBox };
