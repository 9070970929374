import { CoralogixRum } from "@coralogix/browser";
import "@fontsource/roboto";
import "@fontsource/roboto/700.css";
import "@fontsource/space-mono";
import { CustomThemeProvider as DesignThemeProvider } from "@fluidattacks/design";
import mixpanel from "mixpanel-browser";
import type { ComponentType } from "react";
import React, { Suspense, lazy, useEffect, useMemo, useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { GlobalStyle } from "./styles";

import { CustomThemeProvider } from "components/colors";
import { ToastBox } from "components/notification/toast-box";
import type { IAuthContext } from "context/auth";
import { authContext } from "context/auth";
import {
  authzPermissionsContext,
  userLevelPermissions,
} from "context/authz/config";
import type { IFeaturePreviewContext } from "context/featurePreview";
import { featurePreviewContext } from "context/featurePreview";
import type { IMeetingModeContext } from "context/meetingMode";
import { meetingModeContext } from "context/meetingMode";
import { useStoredState } from "hooks";
import { Login } from "pages/login";
import { SignUp } from "pages/sign-up";
import { ApolloProvider } from "utils/apollo";
import { BugsnagErrorBoundary } from "utils/bugsnagErrorBoundary";
import { getEnvironment } from "utils/environment";
import { secureStore, secureStoreContext } from "utils/secureStore";
// eslint-disable-next-line import/no-unresolved
import "vite/modulepreload-polyfill";
import "react-toastify/dist/ReactToastify.min.css";
import "tachyons/css/tachyons.min.css";
import "tachyons-word-break/css/tachyons-word-break.min.css";
import "react-international-phone/style.css";
import "react-tooltip/dist/react-tooltip.css";

const LazyHome = lazy(
  async (): Promise<{ default: ComponentType }> => import("pages/home"),
);

const App: React.FC = (): JSX.Element => {
  // eslint-disable-next-line functional/immutable-data
  window.global = window;

  const [user, setUser] = useState({
    awsSubscription: null,
    tours: {
      newGroup: true,
      newRoot: true,
      welcome: true,
    },
    userEmail: "",
    userName: "",
  } as IAuthContext);
  const [featurePreview, setFeaturePreview] = useStoredState(
    "featurePreview",
    false,
    localStorage,
  );
  const [meetingMode, setMeetingMode] = useStoredState(
    "meetingMode",
    false,
    localStorage,
  );

  useEffect((): void => {
    CoralogixRum.startSessionRecording();
  }, []);

  const valueAuth = useMemo((): IAuthContext => ({ ...user, setUser }), [user]);

  const valueFeature = useMemo(
    (): IFeaturePreviewContext => ({ featurePreview, setFeaturePreview }),
    [featurePreview, setFeaturePreview],
  );

  const valueMeetingMode = useMemo(
    (): IMeetingModeContext => ({ meetingMode, setMeetingMode }),
    [meetingMode, setMeetingMode],
  );

  return (
    <React.StrictMode>
      <GlobalStyle />
      <BugsnagErrorBoundary>
        <BrowserRouter basename={"/"}>
          <ApolloProvider>
            <authzPermissionsContext.Provider value={userLevelPermissions}>
              <secureStoreContext.Provider value={secureStore}>
                <authContext.Provider value={valueAuth}>
                  <featurePreviewContext.Provider value={valueFeature}>
                    <CustomThemeProvider>
                      <DesignThemeProvider>
                        <meetingModeContext.Provider value={valueMeetingMode}>
                          <Routes>
                            <Route element={<Login />} path={"/"} />
                            <Route element={<SignUp />} path={"/SignUp"} />
                            <Route
                              element={
                                <Suspense fallback={<div />}>
                                  <LazyHome />
                                </Suspense>
                              }
                              path={"/*"}
                            />
                          </Routes>
                        </meetingModeContext.Provider>
                      </DesignThemeProvider>
                    </CustomThemeProvider>
                  </featurePreviewContext.Provider>
                </authContext.Provider>
              </secureStoreContext.Provider>
            </authzPermissionsContext.Provider>
          </ApolloProvider>
        </BrowserRouter>
        <ToastBox />
      </BugsnagErrorBoundary>
    </React.StrictMode>
  );
};

mixpanel.init("7a7ceb75ff1eed29f976310933d1cc3e");

const environment = getEnvironment();

if (environment !== "production") {
  mixpanel.disable();
}

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(<App />);
}
